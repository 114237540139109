<template>
  <div class="sidebar" :class="this.$store.state.techStore.isSidebarOpened && 'active'">
    <div class="sidebar__logo">
      <logo />
    </div>
    <nav>
      <ul class="sidebar__nav">
        <li class="sidebar__nav-item">
            <router-link to="/admin/clients">
              <div class="sidebar__nav-text">
                <icon class="sidebar__icon" name="clients" />
                Клиенты
              </div>
              <div class="notify__item">
                <notifyNumber :number="this.$store.state.techStore.clientsCount" />
              </div>
            </router-link>
        </li>
        <li class="sidebar__nav-item">
          <router-link to="/admin/operations">
            <div class="sidebar__nav-text">
              <icon class="sidebar__icon" name="operations"/>
              Операции
            </div>
            <div class="notify__item">
              <notifyNumber :number="this.$store.state.techStore.purchasesCount" />
            </div>
          </router-link>
        </li>
        <li class="sidebar__nav-item">
          <router-link to="/admin/team">
            <div class="sidebar__nav-text">
              <icon class="sidebar__icon" name="team"/>
              Сотрудники
            </div>
          </router-link>
        </li>
        <li class="sidebar__nav-item">
          <router-link to="/admin/messages">
            <div class="sidebar__nav-text">
              <icon class="sidebar__icon" name="messages"/>
              Сообщения
            </div>
            <div class="notify__item">
              <notifyNumber :number="this.$store.state.techStore.messagesCount" />
            </div>
          </router-link>
        </li>
        <li class="sidebar__nav-item">
          <router-link to="/admin/analytics">
            <div class="sidebar__nav-text">
              <icon class="sidebar__icon" name="analytics"/>
              Аналитика
            </div>
            <div class="notify__item">
              <notifyNumber :number="0" />
            </div>
          </router-link>
        </li>
        <li class="sidebar__nav-item">
          <router-link to="/admin/settings">
            <div class="sidebar__nav-text">
              <icon class="sidebar__icon" name="settings"/>
              Настройки
            </div>
          </router-link>
        </li>
        <li class="sidebar__nav-item">
          <router-link to="/admin/import">
            <div class="sidebar__nav-text">
              <icon class="sidebar__icon" name="importbase"/>
              Импорт базы
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="sidebar__subscribe">
      До окончания подписки 14 дней
    </div>
    <div class="sidebar__purchase">
      <router-link class="sidebar__purchase-item" to="/admin/purchase">
        <icon class="sidebar__icon" name="purchase"/>
        Регистрация покупки
      </router-link>
    </div>
  </div>
</template>

<script>
import logo from '@/components/logo'
import notifyNumber from '@/components/notifyNumber'

export default {
  name: 'sidebar',
  components: {
    logo,
    notifyNumber
  }
}
</script>

<style scoped src="./sidebar.scss" lang="scss"/>
