<template>
  <div class="input-wrapper">
    <div class="input-container" :class="{'input-disabled': disabled, 'input-validate': validate}">
      <slot name="before"/>
      <template v-if="textarea">
        <textarea :rows="rows" class="input textarea" :disabled="disabled" :type="type" :value="defaultValue"
                  @focus="resizeTextarea" @keyup="resizeTextarea"
                  :autocomplete="autocomplete" :placeholder="placeholder"
                  @input="onChange"></textarea>
      </template>
      <input v-else class="input" :disabled="disabled" ref="input" :type="type" :value="defaultValue"
             :autocomplete="autocomplete"
             :placeholder="placeholder"
             @input="onChange">
      <slot name="after"/>

      <template v-if="validate">
        <div class="validate-icon">
          <icon name="validate"/>
        </div>
      </template>

    </div>
    <div v-if="validate" class="error">
      {{ validateText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'textInput',
  props: {
    options: {
      type: Object
    },
    placeholder: {
      type: String
    },
    defaultValue: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean
    },
    type: {
      validator(value) {
        return ['text', 'password'].includes(value)
      },
      default: 'text'
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    validate: {
      type: Boolean
    },
    validateText: {
      type: String,
      default: 'Введены некорректные данные'
    },
    textarea: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 3
    }
  },
  methods: {
    onChange(event) {
      const value = event.target.value
      this.$emit('logChange', value)
      this.$emit('onChange', value)

      if(this.options.type === 'integer') {
        const regex = /^[0-9]*?[0-9]*$/;
        if (!regex.test(this.$refs.input.value)) {
          this.$refs.input.value = this.$refs.input.value.slice(0, -1);
        }
      }
      if(this.options.type === 'float') {
        const regex = /^[0-9]*\.?[0-9]*$/;
        if (!regex.test(this.$refs.input.value)) {
          this.$refs.input.value = this.$refs.input.value.slice(0, -1);
        }
      }

      if (value > this.options?.max) {
        this.$refs.input.value = this.options.max
      }
      if (value < this.options?.min) {
        this.$refs.input.value = this.options.min
      }
    }
  }
}
</script>

<style lang="scss" src="./textInput.scss" scoped/>
