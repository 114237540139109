<template>
  <accountContent title="Восстановление пароля" desc="Укажите электронный адрес вашего аккаунта">
    <div class="input-container">
      <form action="javascript:void(0)">
        <div v-if="!!formError" class="error mb-20">
          {{ formError }}
        </div>

        <div class="x-input-item">
          <div class="input-name">E-mail</div>
          <div class="input-content" :class="!!emailError && 'error-wrap'">
            <textInput
              placeholder="example@mail.ru"
              autocomplete="mail username"
              v-bind:defaultValue="email"
              @onChange="onChangeEmail"
            />
          </div>
        </div>
        <div v-if="!!emailError" class="error error-input error-right">
          {{ emailError }}
        </div>
      </form>
    </div>
    <div class="buttons">
      <button @click="$router.push('login')" class="back-button">Назад</button>
      <div class="reg-button">
        <xButton @click="onSendForm">
          Отправить
        </xButton>
      </div>
    </div>
  </accountContent>
</template>

<script>
import accountContent from '@/components/accountContent'
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'

export default {
  name: 'accountNew',
  components: {
    accountContent,
    textInput,
    xButton
  },
  data () {
    return {
      formError: '',
      email: '',
      emailError: ''
    }
  },
  methods: {
    onChangeEmail (val) {
      this.email = val
      // eslint-disable-next-line no-useless-escape
      if (val.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        this.emailError = ''
      }
    },
    validateForm () {
      // eslint-disable-next-line no-useless-escape
      if (!this.email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        this.emailError = 'Укажите корректный e-mail'
      }
    },
    async onSendForm () {
      this.validateForm()

      if (this.emailError) {
        return
      }

      await fetch(window.host + 'users/password/forgot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.email
        })
      }).then(async response => {
        const data = await response.json()

        if (!response.ok) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.$store.commit(
          'popupStore/show',
          {
            useFullWindow: true,
            title: 'Восстановление пароля',
            text: 'Проверьте почту, в письме отправлена ссылка на восстановление пароля',
            callback: async () => {
              await this.$router.push('/login')
            }
          }
        )
      }).catch(err => {
        this.$store.commit(
          'popupStore/show',
          {
            useFullWindow: true,
            title: 'Восстановление пароля',
            text: 'Проверьте почту, в письме отправлена ссылка на восстановление пароля',
            callback: async () => {
              await this.$router.push('/login')
            }
          }
        )
        this.formError = err
        // this.$store.commit('techStore/checkError', { err: err })
      })
    }
  },
  created () {
    this.$store.commit('techStore/checkLogin', { path: 'admin' })
  }
}
</script>

<style lang="scss" src="./accountRestore.scss" scoped />
