<template>
  <div class="x-drag-and-drop" @drop.prevent="drop" @dragover.prevent
       @dragleave.prevent>
    <div class="x-drag-and-drop-content" v-show="!uploaded">
      <icon name="cloud" class="x-drag-and-drop-icon"/>
      <label for="upload">
       <span class="x-drag-and-drop-button">
         Выберите файл
       </span>
      </label>
      <input :accept="validTypes" :multiple="multiple" ref="files" id="upload" style="display: none;" type="file"
             @change="pushFiles"/>
      <div class="x-drag-and-drop-desc">
        <div>Или перетащите фото в это поле.</div>
        <div>Фото должно быть в формате JPG или PNG и весить до 6 Мб.</div>
        <div>Рекомендуемый размер 1080х810px.</div>
      </div>
    </div>
    <div class="x-drag-and-drop-uploaded" v-if="uploaded">
      <div class="x-drag-and-drop-uploaded-item" v-for="(img, id) in converted_files" :key="img">
        <img :src="img" alt="">
        <icon name="close" @click="deleteFile(id)"/>
      </div>
      <div>Фото загружено</div>
    </div>
    <div class="x-drag-and-drop-error">
      <div v-if="error.size">
        Изображение превышает допустимый размер файла 6 Мб.
      </div>
      <div v-if="error.type">
        Допустимы изображения формата png, jpg, jpeg.
      </div>
      <div v-if="error.count">
        Количество изображений превышено.
      </div>
      <div v-if="error.widthHeight">
        Изображение слишком маленькое (минимальная ширина 540px, высота 405px).
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'xDragAndDrop',
  props: {},
  data() {
    return {
      files: [],
      converted_files: [],
      validTypes: ['image/jpeg'],
      uploaded: false,
      error: {
        type: false,
        size: false,
        count: false,
        widthHeight: false
      },
      multiple: false
    }
  },
  watch: {
    files: {
      deep: true,
      handler: function (value) {
        this.uploaded = !!value.length
      }
    }
  },
  computed: {},
  methods: {
    deleteFile(index) {
      this.converted_files.splice(index, 1)
      this.files = Array.from(this.files)
      this.files.splice(index, 1)
      this.validateFiles(this.files)
      this.$refs.files && (this.$refs.files.value = '');
    },
    async validateFiles(files) {
      this.error = { widthHeight: false, count: false, size: false, type: false };
      const validateWidth = async (file) => {
        const img = new Image();
        const reader = new FileReader();

        return new Promise((resolve) => {
          reader.onload = (e) => {
            img.src = e.target.result;

            img.onload = () => {
              if (img.width < 540 || img.height < 405) {
                console.log(`Изображение слишком маленькое: ${file.name}.`);
                resolve(true);
              } else {
                console.log(`Изображение подходит: ${file.name}.`);
                resolve(false);
              }
            };

            img.onerror = () => {
              console.log(`Не удалось загрузить изображение: ${file.name}.`);
              resolve(true);
            };
          };
          reader.readAsDataURL(file);
        });
      }

      for (const file of files) {
        const conditions = [
          { check: !this.validTypes.includes(file.type), error: 'type' },
          { check: file.size > 6 * 1024 * 1024, error: 'size' },
          { check: files.length > 3, error: 'count' },
          { check: await validateWidth(file), error: 'widthHeight' }
        ];

        const invalidCondition = conditions.find(({ check }) => check);

        if (invalidCondition) {
          this.error[invalidCondition.error] = true;
          return;
        }

        this.files.push(file);
      }
    },
    convertFiles(){
      this.converted_files = []

      const reader = new FileReader();
      reader.onload = (e) => {
        this.converted_files.push(e.target.result)
      }
      const length = this.files.length

      let index = 0;
      if (length) {
        const interval = setInterval(() => {
          if (length === index + 1) {
            clearInterval(interval);
          }
          reader.readAsDataURL(this.files[index])
          index++
        }, 300)
      }
    },
    async pushFiles(event) {
      await this.validateFiles([...(event.target.files.length ? event.target.files : event.detail.files)])
      this.convertFiles()
    },
    drop(e) {
      if(!this.uploaded){
        this.$refs.files.dispatchEvent(new CustomEvent('change', {bubbles: true, detail: {files: e.dataTransfer.files}}));
      }
    }
  }
}
</script>

<style lang="scss" src="./xDragAndDrop.scss" scoped/>
