<template>
  <div v-if="step1" class="step-1">
    <div class="layout-1">
      <div class="purchase-content">
        <h3 class="purchase-title">Введите код скидки (6 цифр) <span v-if="usePhone">или номер телефона клиента</span></h3>
        <div class="step-1-input">
          <div class="input-wrapper">
            <div class="input-container" :class="phoneCodeError ? 'input-validate' : ''">
              <input
                class="input"
                v-model="code"
                @input="codeValidating"
              >
              <div v-if="phoneCodeError" class="validate-icon">
                <icon name="validate"/>
              </div>
            </div>
            <div v-if="phoneCodeError" class="error">
              {{ phoneCodeError }}
            </div>
          </div>
        </div>
        <p class="purchase-desc" v-if="usePhone">При вводе номера телефона используйте международный формат.
          Например: +79876543210
          <br><br>
          По номеру телефона доступно только начисление бонусов (списание недоступно)</p>
        <p class="purchase-desc" v-if="!usePhone">Начисление бонусов по номеру телефона отключено</p>
        <xButton @click="toggleStep2" size="small">
          Отправить
        </xButton>
      </div>
    </div>
  </div>
  <div v-if="step2" class="step-2">
    <div class="step-2-header layout-1">
      <div class="step-2-header-item">
        <div class="step-2-header-desc">
          Клиент
        </div>
        <div class="step-2-header-data">
          {{ clientData.name }}
        </div>
      </div>
      <div class="step-2-header-item">
        <div class="step-2-header-desc">
          На бонусном счёте
        </div>
        <div class="step-2-header-data">
          {{ bonusNumberFormat }}
        </div>
      </div>
      <div class="step-2-header-item">
        <p class="step-2-header-rules">
          Справочно:
          <br>
          Срендяя покупка клиента — <b>{{ sumNumberFormat }} руб.</b>
          <br>
          Предлагайте сопутствующие товары и увеличивайте чек!
        </p>
      </div>
    </div>
    <div class="layout-1">
      <div class="step-2-billing">
        <div class="step-2-billing-item">
          <div class="step-2-billing-text">
            Сумма к оплате
          </div>

          <moneyInput v-model="moneyInt" :min="0.01" :max="100000000.00" :validateText="'Укажите значение от 0,01 до 100 000 000'" :defaultValue="moneyInt" :validate="moneyError" @customValid="moneyValidating" />
        </div>
        <div class="step-2-billing-item">
          <div class="step-2-billing-text">
            Списание бонусов
          </div>

          <moneyInput v-model="bonus" :disabled="!clientData.isTypeCode" :validateText="'Укажите значение от 0,01, но не более, чем доступно к списанию'" :defaultValue="bonus" :validate="bonusError" @customValid="bonusValidating" />
        </div>
        <div v-if="clientData.isTypeCode" class="step-2-billing-desc">
          <span class="step-2-billing-desc-text">
            Доступно к списанию с бонусного счета — {{ bonusCanUseNumberFormat }}
          </span>
          <button @click="spendAllBonuses" class="link">Списать все</button>
        </div>
        <div class="step-2-billing-item">
          <div class="step-2-billing-text">
            К оплате деньгами
          </div>
          <textInput :defaultValue="purchaseCalc" :disabled="true"/>
        </div>
        <div class="step-2-billing-button">
          <xButton @click="toggleStep3" size="fullwidth">
            Провести операцию
          </xButton>
        </div>
      </div>
    </div>
  </div>
  <transition name="purchaseSuccess" :duration="100">
    <div v-if="step3" class="step-3">
      <div class="layout-1">
        <div class="step-3-content">
          <icon name="success" class="step-3-icon" />
          <p class="step-3-thankyou">
            Операция прошла успешно
          </p>
          <a href="" class="link step-3-link">
            Продолжить
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import xButton from '@/components/xButton'
import textInput from '@/components/textInput'
import moneyInput from '@/components/moneyInput';
import { VMoney } from 'v-money'

const pluralize = (count, words, formattedCount = false) => {
  const countN = parseInt(count)
  const cases = [2, 0, 1, 1, 1, 2]
  const printCount = formattedCount || count.toString()
  return printCount + ' ' + words[(countN % 100 > 4 && countN % 100 < 20) ? 2 : cases[Math.min(countN % 10, 5)]]
}

export default {
  name: 'purchase',
  components: {
    xButton,
    textInput,
    moneyInput
  },
  props: {
    vPrivate: {
      type: Boolean
    }
  },
  data () {
    return {
      phoneCodeError: '',
      moneyError: false,
      bonusError: false,
      clientData: {
        adminId: 0,
        isTypeCode: true,
        name: '',
        bonuses: 0,
        averageCheck: 0
      },
      step2: false,
      step3: false,
      step1: true,
      code: undefined,
      bonus: '0.00',
      moneyInt: undefined,
      usePhone: true,
      money: {
        decimal: '.',
        thousands: ' ',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    }
  },
  computed: {
    moneyMask () {
      return '099.99'
    },
    bonusNumberFormat () {
      return pluralize(
        this.clientData.bonuses,
        ['бонус', 'бонуса', 'бонусов'],
        this.formatSum(this.clientData.bonuses, true)
      )
    },
    bonusesCanUse () {
      const money = parseFloat(this.moneyInt ? this.moneyInt.replace(/\s/g, '') : 0)
      const percentCanUse = parseFloat(this.clientData.percentCanUse)
      let canUseBonuses = money / 100 * percentCanUse;

      if (canUseBonuses > this.clientData.bonuses) {
        canUseBonuses = this.clientData.bonuses;
      }

      if (canUseBonuses > 0) {
        return this.numberWithSpaces(canUseBonuses)
      } else {
        return 0.00
      }
    },
    bonusCanUseNumberFormat () {
      let canUseBonuses = this.bonusesCanUse
      const money = parseFloat(this.moneyInt ? this.moneyInt.replace(/\s/g, '') : 0)
      if (money < canUseBonuses ? parseFloat(canUseBonuses.replace(/\s/g, '')) : 0) {
        canUseBonuses = money
      }
      return pluralize(
        parseInt(canUseBonuses ? canUseBonuses.replace(/\s/g, '') : 0),
        ['бонус', 'бонуса', 'бонусов'],
        canUseBonuses
      )
    },
    sumNumberFormat () {
      return this.formatSum(this.clientData.averageCheck)
    },
    purchaseCalc () {
      if (this.bonus === 0) {
        return this.moneyInt
      } else {
        const sum = (this.moneyInt ? parseFloat(this.moneyInt.replace(/\s/g, '')) : 0) - parseFloat(this.bonus ? this.bonus.replace(/\s/g, '') : 0)

        if (sum <= 0) {
          return ''
        } else {
          return this.numberWithSpaces(sum)
        }
      }
    }
  },
  methods: {
    numberWithSpaces (x) {
      const parts = parseFloat(x).toFixed(2).toString().split('.');

      if (x.length > 0) {
        if (parts.length > 1) {
          if (parts[1].length === 0) {
            parts[1] = '00'
          } else if (parts[1].length === 1) {
            parts[1] = parts[1] + '0'
          }
        } else {
          parts.push('00')
        }
      }

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      return parts.join('.');
    },
    spendAllBonuses () {
      if (this.clientData.isTypeCode) {
        this.bonus = this.bonusesCanUse
      }
    },
    formatSum (sum, isBonusNumber = false) {
      const formatted = (new Intl.NumberFormat('ru-RU').format(sum)).replace(',', '.').split('.')

      if (!isBonusNumber) {
        if (formatted.length === 1) {
          formatted.push('00')
        } else if (formatted[1].length === 1) {
          formatted[1] = formatted[1] + '0'
        }
      }

      return formatted.join('.')
    },
    codeValidating (event) {
      const value = event.target.value
      const splitVal = value.split('+')
      const isPhone = splitVal.length === 2
      const forTestValue = isPhone ? splitVal[1] : splitVal[0]
      if (/\D/gmu.test(forTestValue)) {
        const correctValue = forTestValue.replace(/\D/gmu, '')
        this.code = `${isPhone ? '+' : ''}${correctValue}`
      }
      this.phoneCodeError = ''
    },
    bonusValidating (event) {
      const value = event.target.value

      if (parseFloat(value.replace(/\D/gmu, '')) > parseFloat(this.bonusesCanUse.replace(/\D/gmu, ''))) {
        this.bonus = event.target.value = this.bonusesCanUse
      } else {
        this.bonus = value
      }
    },
    moneyValidating (event) {
      this.moneyInt = event.target.value
      this.moneyError = false

      if (parseFloat(this.bonus ? this.bonus.replace(/\D/gmu, '') : 0) > parseFloat(this.bonusesCanUse ? this.bonusesCanUse.replace(/\D/gmu, '') : 0)) {
        this.bonus = this.bonusesCanUse
      }
    },
    toggleStep2 () {
      if (this.code.length > 6 && this.code.length !== 12 && this.usePhone) {
        this.phoneCodeError = 'Получите у клиента код скидки или номер телефона'
      }

      if (this.usePhone) {
        if (this.code.replace(/\D/gmu, '').length < 6) {
          this.phoneCodeError = 'Введите не менее 6 цифр'
        }
      } else {
        if (this.code.replace(/\D/gmu, '').length !== 6) {
          this.phoneCodeError = 'Введите 6 цифр'
        }
      }

      if (!this.phoneCodeError) {
        fetch(`${window.host}purchases/step1/?search=${this.code}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          }
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            if (data && data.message) {
              this.$store.commit(
                'popupStore/show',
                { text: data.message, useFullWindow: false }
              )
            }
            return Promise.reject(error)
          }

          const canUsePercent = data.data.percentCanUse

          this.clientData.adminId = data.data.id
          this.clientData.isTypeCode = data.data.type === 'code'
          this.clientData.name = data.data.name
          this.clientData.bonuses = data.data.bonus_number
          this.clientData.percentCanUse = canUsePercent
          this.clientData.averageCheck = data.data.average

          this.step2 = true
          this.step1 = false
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }
    },
    toggleStep3 () {
      if (!this.moneyInt) {
        this.moneyError = true
      }

      if (!this.moneyError && !this.bonusError) {
        fetch(`${window.host}clients/${this.clientData.adminId}/purchases/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          },
          body: JSON.stringify({
            roubles: this.purchaseCalc,
            bonuses: this.bonus,
            sum: this.moneyInt
          })
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          this.step3 = true
          this.step2 = false
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }
    }
  },
  emits: ['titleName'],
  created () {
    this.$emit('titleName', 'Регистрация покупки')

    if (!this.vPrivate) {
      fetch(window.host + 'users/activities', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(async response => {
        const data = await response.json()

        if (!response.ok || !data.success) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }
        this.usePhone = data.data.usePhone
      })
    }
  },
  directives: {
    money: VMoney
  }
}
</script>

<style lang="scss" src="./purchase.scss" scoped/>
<style lang="scss" src="./../../components/textInput/textInput.scss" scoped/>
