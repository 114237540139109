<template>
  <div class="rate-table-wrapper">
    <div class="top-descriptor descriptor">
      Частота покупок
    </div>
    <div class="left-descriptor descriptor">
      Давность покупок
    </div>
    <table class="rate-table">
      <tbody>
      <tr>
        <td class="desc"></td>
        <td class="desc desc-horizontal">
          <div>Покупает часто</div>
        </td>
        <td class="desc desc-horizontal">
          <div>Не так часто</div>
        </td>
        <td class="desc desc-horizontal">
          <div>Редко</div>
        </td>
      </tr>
      <tr>
        <td class="desc">
          <div>Недавно</div>
        </td>
        <td>
          <div>
            <slot name="top-1" />
          </div>
        </td>
        <td>
          <div>
            <slot name="top-2" />
          </div>
        </td>
        <td>
          <div>
            <slot name="top-3" />
          </div>
        </td>
      </tr>
      <tr>
        <td class="desc">
          <div>Не так давно</div>
        </td>
        <td>
          <div>
            <slot name="mid-1" />
          </div>
        </td>
        <td>
          <div>
            <slot name="mid-2" />
          </div>
        </td>
        <td>
          <div>
            <slot name="mid-3" />
          </div>
        </td>
      </tr>
      <tr>
        <td class="desc">
          <div>Давно</div>
        </td>
        <td>
          <div>
            <slot name="bottom-1" />
          </div>
        </td>
        <td>
          <div>
            <slot name="bottom-2" />
          </div>
        </td>
        <td>
          <div>
            <slot name="bottom-3" />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'rateTable'
}
</script>

<style lang="scss" src="./rateTable.scss" scoped />
