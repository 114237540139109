<template>
  <publicArea :humans="false">
    <div class="instruction-container">
      <accountContent
        title="Видеоинструкция «Быстрый старт»"
        desc="Держите инструкцию открытой в отдельной вкладке браузера. Рекомендуем действовать так: смотрите часть, ставите на паузу, делаете настройку, смотрите дальше."
      >
        <div class="video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/9tobL8U7dQo?showinfo=0&amp;rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <footer class="instruction-footer">
          <p class="desc">Вы всегда сможете вернуться к этой инструкции в будущем. Для этого нажмите «?» - он есть на каждой странице нашей системы.</p>
          <div class="button-container">
            <xButton @click="settingsAction">Начать настройку</xButton>
          </div>
        </footer>
      </accountContent>
    </div>
  </publicArea>
</template>

<script>
import publicArea from '@/components/publicArea'
import accountContent from '@/components/accountContent'
import xButton from '@/components/xButton'

export default {
  name: 'videoInstruction',
  components: {
    publicArea,
    accountContent,
    xButton
  },
  methods: {
    settingsAction () {
      if (this.$store.state.techStore.isAuth) {
        const routeData = this.$router.resolve('/admin/settings/profile')
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style lang="scss" src="./videoInstruction.scss" scoped />
