<template>
  <div class="layout-1">
    <div class="connect">
      <h2 class="title">Подключение СМС-сервиса</h2>
        <p class="connect-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <div class="connect-content">
          <div class="connect-input">
            <textInput placeholder="Введите ключ" />
          </div>
          <div class="connect-button">
            <xButton>Сохранить</xButton>
          </div>
        </div>
    </div>
  </div>
  <div class="layout-1 connect-info">
    <div class="connect">
      <h2 class="title">Чтобы подключить СМС-сервис нужно:</h2>
      <p class="connect-desc">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur sit sem nulla bibendum amet, feugiat quam. Est tortor, magna sapien, scelerisque congue velit in pulvinar. Urna, nibh fusce elit adipiscing euismod in. Tortor, orci aenean sed cras tellus a ornare tellus viverra. Tortor in ullamcorper nec in facilisis id et ateget pretium mauris ac.
      </p>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'

export default {
  name: 'smsIntegration',
  components: {
    textInput,
    xButton
  }
}
</script>

<style lang="scss" src="./smsIntegration.scss" scoped />
