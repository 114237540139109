<template>
  <div class="layout-1">
    <div class="behavior-time analytics-block">
      <div class="behavior-header">
        <h2 class="title">Анализ давности покупок</h2>
        <p class="behavior-header-desc">(Дней назад от текущей даты)</p>
      </div>
      <div class="analytics-graph">
        <VueHighcharts
          type="chart"
          :options="longTimePurchaseOptions"
          :animateOnUpdate="true"
        />
      </div>
      <p class="behavior-desc">
        Клиенты, которые покупали недавно подтвердили, что они с вами. Клиенты, которые покупали давно, возможно, ушли к конкурентам. Пригласите их к себе за покупками.
        <br><br>
        Нажмите на цифру – посмотрите список этих клиентов.
      </p>
    </div>
    <div class="behavior-frequency analytics-block">
      <div class="behavior-header">
        <h2 class="title">Анализ частоты покупок</h2>
        <p class="behavior-header-desc">(Покупок в год)</p>
      </div>
      <div class="analytics-graph">
        <VueHighcharts
          type="chart"
          :options="frequencyOptions"
          :animateOnUpdate="true"
        />
      </div>
      <p class="behavior-desc">
        Клиенты, которые делают покупки часто — ваш основной актив. Предлагайте им сопутствующие товары — увеличивайте чек. Для клиентов, которые покупают редко сделайте стимулирующие акции.        <br><br>
        <br><br>
        Нажмите на цифру – посмотрите список этих клиентов.
      </p>
    </div>
    <div class="behavior-averagecheck analytics-block">
      <div class="behavior-header">
        <h2 class="title">Анализ среднего чека клиентов</h2>
        <p class="behavior-header-desc">(Руб.)</p>
      </div>
      <div class="analytics-graph">
        <VueHighcharts
          type="chart"
          :options="averageCheckOptions"
          :animateOnUpdate="true"
        />
      </div>
      <p class="behavior-desc">
        Клиенты с большим чеком — ваш основной актив. Предлагайте им дорогие товары — как правило, они готовы их покупать. Для клиентов с низким чеком делайте пакетные предложения — увеличивайте чек.        <br><br>
        Нажмите на цифру – посмотрите список этих клиентов.
      </p>
    </div>
    <div class="behavior-rate analytics-block">
      <h2 class="behavior-rate-title">
        Выручка = Количество покупок (частота) * Средний чек
      </h2>
      <p class="behavior-rate-desc">
        Ниже приводим наши рекомендации по работе с каждой группой клиентов для увеличения Количества покупок и/или Среднего чека. Чтобы посмотреть рекомендации, нажмите на
      </p>
      <p class="behavior-rate-recommendations">
        Нажмите на ссылку с количеством клиентов и отправьте предложение именно этой группе клиентов. Не отправляйте сообщения часто – нет клиентов, которые любят назойливость
      </p>
      <div class="behavior-rate-content">
        <div class="behavior-rate-item">
          <div class="behavior-rate-header">
            <h2 class="title">
              Клиенты с высоким чеком покупок
            </h2>
            <note note-position="bottom-to-right">
              Ваши клиенты разделены на 3 группы по размеру их среднего чека. Если клиент еще не делал покупок, он не попадает ни в одну из групп.
              <br><br>
              <b>Частота покупок:</b><br>
              Ваши клиенты разделены на 3 группы по частоте покупок. Если клиент сделал менее 2 покупок в разные дни, он не попадает ни в одну из групп.
              <br><br>
              <b>Давность покупок:</b><br>
              Ваши клиенты разделены на 3 группы по давности последней покупки. Если клиент еще не делал покупок, он не попадает ни в одну из групп.
            </note>
          </div>
          <div class="behavior-rate-table">
            <rateTable>
              <template #top-1>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #top-2>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #top-3>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #mid-1>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #mid-2>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #mid-3>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #bottom-1>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #bottom-2>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #bottom-3>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
            </rateTable>
          </div>
        </div>
        <div class="behavior-rate-item">
          <div class="behavior-rate-header">
            <h2 class="title">
              Клиенты со средним чеком покупок
            </h2>
            <note note-position="bottom-to-right">
              Ваши клиенты разделены на 3 группы по размеру их среднего чека. Если клиент еще не делал покупок, он не попадает ни в одну из групп.
              <br><br>
              <b>Частота покупок:</b><br>
              Ваши клиенты разделены на 3 группы по частоте покупок. Если клиент сделал менее 2 покупок в разные дни, он не попадает ни в одну из групп.
              <br><br>
              <b>Давность покупок:</b><br>
              Ваши клиенты разделены на 3 группы по давности последней покупки. Если клиент еще не делал покупок, он не попадает ни в одну из групп.
            </note>
          </div>
          <div class="behavior-rate-table">
            <rateTable>
              <template #top-1>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #top-2>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #top-3>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #mid-1>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #mid-2>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #mid-3>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #bottom-1>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #bottom-2>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #bottom-3>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
            </rateTable>
          </div>
        </div>
        <div class="behavior-rate-item">
          <div class="behavior-rate-header">
            <h2 class="title">
              Клиенты с низким чеком покупок
            </h2>
            <note note-position="bottom-to-right">
              Ваши клиенты разделены на 3 группы по размеру их среднего чека. Если клиент еще не делал покупок, он не попадает ни в одну из групп.
              <br><br>
              <b>Частота покупок:</b><br>
              Ваши клиенты разделены на 3 группы по частоте покупок. Если клиент сделал менее 2 покупок в разные дни, он не попадает ни в одну из групп.
              <br><br>
              <b>Давность покупок:</b><br>
              Ваши клиенты разделены на 3 группы по давности последней покупки. Если клиент еще не делал покупок, он не попадает ни в одну из групп.
            </note>
          </div>
          <div class="behavior-rate-table">
            <rateTable>
              <template #top-1>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #top-2>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #top-3>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #mid-1>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #mid-2>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #mid-3>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #bottom-1>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #bottom-2>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
              <template #bottom-3>
                <router-link class="link" to="/admin/clients">16 клиентов</router-link>
              </template>
            </rateTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import VueHighcharts from 'vue3-highcharts'
import rateTable from '@/components/rateTable'
import note from '@/components/note'

export default {
  name: 'analyticsBehavior',
  components: {
    VueHighcharts,
    rateTable,
    note
  },
  setup () {
    const router = useRouter()
    const longTimePurchaseData = ref([24, 22, 19, 20, 17, 13, 7, 8, 5])
    const frequencyData = ref([147, 31, 22, 7, 3, 0, 0, 0, 0])
    const averageCheckData = ref([45, 30, 22, 11, 12, 8, 8, 7, 5])

    const columnRoutesData = ref()

    const longTimePurchaseOptions = computed(() => ({
      chart: {
        type: 'column'
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      subtitle: {
        text: null
      },
      xAxis: {
        categories: ['от 1 до 36',
          'от 37 до 72',
          'от 73 до 108',
          'от 109 до 144',
          'от 145 до 180',
          'от 181 до 216',
          'от 217 до 252',
          'от 253 до 288',
          'от 289 до 327'],
        plotBands: [{
          from: -1,
          to: 2.5,
          color: '#C6FFC19C'
        }, {
          from: 2.5,
          to: 5.5,
          color: '#F6F4F9'
        }, {
          from: 5.5,
          to: 9,
          color: '#FF272726'
        }]
      },
      colors: ['#552490'],
      yAxis: {
        title: {
          text: null
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              color: '#FF7F27',
              fontSize: '15px'
            }
          }

        },
        series: {
          cursor: 'pointer',

          events: {
            click: function (event) {
              columnRoutesData.value = event.point.options.y
            }
          }
        }
      },
      series: [{
        data: longTimePurchaseData.value
      }]
    }))
    const frequencyOptions = computed(() => ({
      chart: {
        type: 'column'
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      subtitle: {
        text: null
      },
      xAxis: {
        plotBands: [{
          from: -1,
          to: 2.5,
          color: '#FF272726'
        }, {
          from: 2.5,
          to: 5.5,
          color: '#F6F4F9'
        }, {
          from: 5.5,
          to: 9,
          color: '#C6FFC19C'
        }],
        categories: ['от 1 до 36',
          'от 37 до 72',
          'от 73 до 108',
          'от 109 до 144',
          'от 145 до 180',
          'от 181 до 216',
          'от 217 до 252',
          'от 253 до 288',
          'от 289 до 327']
      },
      colors: ['#552490'],
      yAxis: {
        title: {
          text: null
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              color: '#FF7F27',
              fontSize: '15px'
            }
          }

        },
        series: {
          cursor: 'pointer',
          events: {
            click: function (event) {
              columnRoutesData.value = event.point.options.y
            }
          }
        }
      },
      series: [{
        data: frequencyData.value
      }]
    }))
    const averageCheckOptions = computed(() => ({
      chart: {
        type: 'column'
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      subtitle: {
        text: null
      },
      xAxis: {
        plotBands: [{
          from: -1,
          to: 2.5,
          color: '#FF272726'
        }, {
          from: 2.5,
          to: 5.5,
          color: '#F6F4F9'
        }, {
          from: 5.5,
          to: 9,
          color: '#C6FFC19C'
        }],
        categories: ['от 1 до 36',
          'от 37 до 72',
          'от 73 до 108',
          'от 109 до 144',
          'от 145 до 180',
          'от 181 до 216',
          'от 217 до 252',
          'от 253 до 288',
          'от 289 до 327']
      },
      colors: ['#552490'],
      yAxis: {
        title: {
          text: null
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              color: '#FF7F27',
              fontSize: '15px'
            }
          }

        },
        series: {
          cursor: 'pointer',

          events: {
            click: function (event) {
              columnRoutesData.value = event.point.options.y
            }
          }
        }
      },
      series: [{
        data: averageCheckData.value
      }]
    }))
    watch(columnRoutesData, () => {
      router.push('/admin/clients')
    })

    return {
      longTimePurchaseOptions,
      frequencyOptions,
      averageCheckOptions
    }
  }
}
</script>

<style lang="scss" src="./analyticsBehavior.scss" scoped />
<style lang="scss" src="../analytics/analytics.scss" scoped/>
