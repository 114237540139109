<template>
  <div v-if="number > 0" class="actions__number">{{100 > number ? number : '99+'}}</div>
</template>

<script>
export default {
  name: 'notifyNumber',
  props: {
    number: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" src="./notifyNumber.scss" scoped />
