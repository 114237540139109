<template>
  <adminNav>
    <router-link to="/admin/import/base">
      Импорт базы
    </router-link>
    <router-link to="/admin/import/sms-first">
      Настройка стартового СМС
    </router-link>
    <router-link to="/admin/import/sms-integration">
      Интеграция с СМС-сервисом
    </router-link>
  </adminNav>
  <router-view />
</template>

<script>
import adminNav from '@/components/adminNav/adminNav'

export default {
  name: 'import',
  components: {
    adminNav
  },
  emits: ['titleName'],
  created () {
    this.$emit('titleName', 'Импорт базы')
  }
}
</script>

<style lang="scss" src="./importView.scss" scoped />
