<template>
  <div class="integrations-wrapper">
    <adminNav>
      <a
        href="/admin/settings/integrations/web"
        :class="location.includes('web') && 'router-link-active router-link-exact-active'"
        @click="e => onClickLink(e, 'web')"
      >
        Касса и сайты
      </a>
      <a
        href="/admin/settings/integrations/telegram"
        :class="location.includes('telegram') && 'router-link-active router-link-exact-active'"
        @click="e => onClickLink(e, 'telegram')"
      >
        Telegram
      </a>
      <a
        href="/admin/settings/integrations/viber"
        :class="location.includes('viber') && 'router-link-active router-link-exact-active'"
        @click="e => onClickLink(e, 'viber')"
      >
        Viber
      </a>
      <a
        href="/admin/settings/integrations/vk"
        :class="location.includes('vk') && 'router-link-active router-link-exact-active'"
        @click="e => onClickLink(e, 'vk')"
      >
        VK
      </a>
    </adminNav>
  </div>
  <router-view />
</template>

<script>
import adminNav from '@/components/adminNav'

export default {
  name: 'settingsIntegrations',
  components: {
    adminNav
  },
  data () {
    return {
      location: ''
    }
  },
  watch: {
    $route () {
      this.location = window.location.href
    }
  },
  methods: {
    onClickLink (e, path) {
      if (this.$store.state.techStore.isFormChanged) {
        e.preventDefault()
        const fullPath = `/admin/settings/integrations/${path}`

        this.$store.commit(
          'popupStore/show',
          {
            text: 'Вы не сохранили изменения. Внесенные изменения будут потеряны',
            buttonText: 'Сохранить изменения',
            title: 'Внимание',
            rollBackButtonText: 'Выйти без сохранения',
            useFullWindow: false,
            callback: async () => {
              this.$store.state.techStore.formSaveCallback()
              await this.$router.push(fullPath)
            },
            errorCallback: async () => {
              await this.$router.push(fullPath)
              this.$store.commit(
                'techStore/setFormChanged',
                {
                  isChanged: false,
                  callback: () => {}
                }
              )
            }
          }
        )
      }
    }
  },
  created () {
    this.location = window.location.href
  }
}
</script>

<style lang="scss" src="./settingsIntegrations.scss" scoped />
