<template>
  <adminNav>
    <router-link :to="`/admin/customer/${id}/information`">
      Информация
    </router-link>
    <router-link :to="`/admin/customer/${id}/purchases`">
      Покупки
    </router-link>
    <router-link :to="`/admin/customer/${id}/bonuses`">
      Бонусы
    </router-link>
    <router-link :to="`/admin/customer/${id}/recommendations`">
      Рекомендации
    </router-link>
    <router-link :to="`/admin/customer/${id}/messages`">
      Сообщения
    </router-link>
  </adminNav>
  <router-view @titleName="handleName"/>
</template>

<script>
import adminNav from '@/components/adminNav'

export default {
  name: 'customer',
  components: {
    adminNav
  },
  data () {
    return {
      id: 0
    }
  },
  methods: {
    handleName (name) {
      this.$emit('titleName', name)
    }
  },
  created () {
    const id = this.$router.currentRoute.value.params.id
    if (isNaN(parseInt(id))) {
      this.$router.push({
        name: 'error'
      })
    }

    this.id = id
  }
}
</script>

<style lang="scss" src="./customer.scss" scoped />
