<template>
  <adminNav>
    <a
      href="/admin/settings/profile"
      :class="location.includes('profile') && 'router-link-active router-link-exact-active'"
      @click="e => onClickLink(e, 'profile')"
    >
      Профиль компании
    </a>
    <a
      href="/admin/settings/bonuses"
      :class="location.includes('bonuses') && 'router-link-active router-link-exact-active'"
      @click="e => onClickLink(e, 'bonuses')"
    >
      Бонусная программа
    </a>
    <a
      href="/admin/settings/referrals"
      :class="location.includes('referrals') && 'router-link-active router-link-exact-active'"
      @click="e => onClickLink(e, 'referrals')"
    >
      Реферальная программа
    </a>
    <a
      href="/admin/settings/service"
      :class="location.includes('service') && 'router-link-active router-link-exact-active'"
      @click="e => onClickLink(e, 'service')"
    >
      Сервисные сообщения
    </a>
    <a
      href="/admin/settings/integrations"
      :class="location.includes('integrations') && 'router-link-active router-link-exact-active'"
      @click="e => onClickLink(e, 'integrations')"
    >
      Интеграции
    </a>
  </adminNav>
  <router-view />
</template>

<script>
import adminNav from '@/components/adminNav'

export default {
  name: 'settings',
  components: {
    adminNav
  },
  emits: ['titleName'],
  data () {
    return {
      location: ''
    }
  },
  watch: {
    $route () {
      this.location = window.location.href
    }
  },
  methods: {
    onClickLink (e, path) {
      if (this.$store.state.techStore.isFormChanged) {
        e.preventDefault()
        const fullPath = `/admin/settings/${path}`

        this.$store.commit(
          'popupStore/show',
          {
            text: 'Вы не сохранили изменения. Внесенные изменения будут потеряны',
            buttonText: 'Сохранить изменения',
            title: 'Внимание',
            rollBackButtonText: 'Выйти без сохранения',
            useFullWindow: false,
            callback: async () => {
              this.$store.state.techStore.formSaveCallback()
              await this.$router.push(fullPath)
            },
            errorCallback: async () => {
              await this.$router.push(fullPath)
              this.$store.commit(
                'techStore/setFormChanged',
                {
                  isChanged: false,
                  callback: () => {}
                }
              )
            }
          }
        )
      }
    }
  },
  created () {
    this.location = window.location.href
    this.$emit('titleName', 'Настройки')
  }
}
</script>

<style lang="scss" scoped src="./settings.scss" />
