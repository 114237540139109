<template>
  <div class="layout-1">
    <div class="agreement">
      <checkboxInput />
      <div class="agreement-text">
        Я подтверждаю, что имею согласие на обработку персональных данных клиентов.
        <br> Все риски претензий со стороны клиентов по поводу отправки стартового СМС беру на себя
      </div>
    </div>
    <div class="message-text">
      <h4 class="message-title">Текст сообщения:</h4>
      <textInput :textarea="true" :rows="5" default-value="Здравствуйте!
Lorem ipsum dolor sit amet, consectetur adipiscing elit lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit lorem ipsum dolor sit amet, consectetur adipiscing elit. consectetur adipiscing elit lorem ipsum dolor sit amet, consectetur adipiscing elit. " />
    </div>
    <div class="sms-footer">
      <div class="sms-footer-button">
        <xButton>
          Отправить
        </xButton>
      </div>
      <p class="sms-footer-desc">
        В одном СМС-сообщении — 70 символов. Для отправки этого текста требуется 3 СМС.
      </p>
    </div>
  </div>
</template>

<script>
import checkboxInput from '@/components/checkboxInput'
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'

export default {
  name: 'smsFirst',
  components: {
    checkboxInput,
    textInput,
    xButton
  }
}
</script>

<style lang="scss" src="./smsFirst.scss" scoped />
