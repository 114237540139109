<template>
  <div class="analytics-header">
    <adminNav>
      <router-link to="/admin/analytics/clients">
        Клиенты
      </router-link>
      <router-link to="/admin/analytics/operations">
        Операции
      </router-link>
      <router-link to="/admin/analytics/behavior">
        Поведение клиентов
      </router-link>
      <router-link to="/admin/analytics/activity">
        Подозрительная активность
        <div class="notify__item">
          <notifyNumber :number="18" />
        </div>
      </router-link>
    </adminNav>
  </div>
  <div class="analytics-filter">
    <xFilter />
  </div>
  <router-view />
</template>

<script>
import adminNav from '@/components/adminNav'
import notifyNumber from '@/components/notifyNumber'
import xFilter from '@/components/xFilter'

export default {
  name: 'analytics',
  components: {
    adminNav,
    notifyNumber,
    xFilter
  },
  emits: ['titleName'],
  created () {
    this.$emit('titleName', 'Аналитика')
  }
}
</script>

<style lang="scss" src="./analytics.scss" scoped />
