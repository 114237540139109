<template>
  <div class="analytics-wrapper">
    <div class="analytics-layout layout-1">
      <div class="note-wrapper">
        <h2 class="title">
          Превышены критические показатели
        </h2>
        <note note-position="bottom-to-right">
          Превышение критических показателей указывает на ненормальную активность пользователей. В том числе, это может указывать на попытку мошенничества с бонусами. Чтобы контролировать возникновение таких ситуаций, настройте показатели ненормальной активности тут:
          <br><br>
          <router-link class="link" to="/admin/settings/bonuses">
            Настройки/Бонусная программа
          </router-link>
        </note>
      </div>
      <div class="activity-container">
        <router-link to="/admin" class="activity-item">
          <div class="activity-item-text">Количество покупок за день</div>
          <div class="link">2</div>
        </router-link>
        <router-link to="/admin" class="activity-item">
          <div class="activity-item-text">Количество рекомендаций за день</div>
          <div class="link">0</div>
        </router-link>
        <router-link to="/admin" class="activity-item">
          <div class="activity-item-text">Количество покупок за последние 7 дней</div>
          <div class="link">4</div>
        </router-link>
        <router-link to="/admin" class="activity-item">
          <div class="activity-item-text">Количество рекомендаций за последние 7 дней</div>
          <div class="link">1</div>
        </router-link>
        <router-link to="/admin" class="activity-item">
          <div class="activity-item-text">Количество покупок за последние 30 дней</div>
          <div class="link">4</div>
        </router-link>
        <router-link to="/admin" class="activity-item">
          <div class="activity-item-text">Количество рекомендаций за последние 30 дней</div>
          <div class="link">7</div>
        </router-link>
      </div>
    </div>
    <div class="analytics-notify">
      <adminNotify align="center" color="orange">
        Нажмите на цифру — эта выборка откроется на вкладке “Клиенты”. Вникните в историю покупок и/или историю рекомендаций. <br> При наличии камер посмотрите, как проводились эти покупки. В итоге — примите управленческое решение по каждому случаю в отдельности.
      </adminNotify>
    </div>
  </div>
</template>

<script>
import adminNotify from '@/components/adminNotify'
import note from '@/components/note'

export default {
  name: 'analyticsActivity',
  components: {
    adminNotify,
    note
  }
}
</script>

<style lang="scss" src="./analyticsActivity.scss" scoped />
