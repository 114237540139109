<template>
  <div class="input-wrapper">
    <div class="input-container" :class="{'input-disabled': disabled, 'input-validate': validate}">
      <slot name="before"/>
      <input class="input" :disabled="disabled" type="text" :value="defaultValue" :autocomplete="autocomplete"
             :placeholder="placeholder"
             @input="onChange"
             @focusout="focusOut"
             @focusin="focusIn"
      >
      <slot name="after"/>

      <template v-if="validateField">
        <div class="validate-icon">
          <icon name="validate"/>
        </div>
      </template>

    </div>
    <div v-if="validateField" class="error">
      {{ validateText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'textInput',
  props: {
    placeholder: {
      type: String
    },
    defaultValue: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    validateText: {
      type: String,
      default: 'Введены некорректные данные'
    },
    rows: {
      type: Number,
      default: 3
    },
    min: {
      defaultValue: false
    },
    max: {
      defaultValue: false
    },
    validate: {
      type: Boolean
    }
  },
  data () {
    return {
      validateField: this.validate
    };
  },
  watch: {
    validate (newVal) {
      this.validateField = newVal
    }
  },
  methods: {
    onChange (event) {
      let value = event.target.value
      this.$emit('logChange', value)
      this.$emit('onChange', value)
      value = value.replace(/[,]/g, '.')
      event.target.value = value.match(/^\d+\.?\d{0,2}/)

      this.$emit('customValid', event)
    },
    focusOut (event) {
      const value = event.target.value

      if (value.length > 0) {
        const splitValue = value.split('.')
        if (splitValue.length > 1) {
          if (splitValue[1].length === 0) {
            event.target.value = value + '00'
          } else if (splitValue[1].length === 1) {
            event.target.value = value + '0'
          }
        } else {
          event.target.value = value + '.00'
        }
      }

      if (this.min || this.max) {
        const floatValue = parseFloat(event.target.value)
        if (this.min && floatValue < this.min) {
          this.validateField = true
        }

        if (this.max && floatValue > this.max) {
          this.validateField = true
        }
      }

      event.target.value = this.numberWithSpaces(event.target.value)

      this.$emit('customValid', event)
    },
    focusIn (event) {
      let value = event.target.value
      value = value.replace(/[ ]/g, '')
      event.target.value = value
    },
    numberWithSpaces (x) {
      const parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    }
  }
}
</script>

<style lang="scss" src="./moneyInput.scss" scoped/>
